import styled from "styled-components";
import NoticiaBackground from "../assets/noticia-background.jpg";

const LocalBusca = styled.div`
  height: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  //background-color: #000d68;
  background: url(${NoticiaBackground});
  background-size: cover;
  flex-direction: column;

  h1 {
    color: #fff;
    width: 100%;
    text-align: center;
    font-size: 5rem;
    font-weight: 500;
    margin-bottom: -10px;
    @media (max-width: 720px) {
      font-weight: 600;
      font-size: 3rem;
    }
  }
  h4 {
    color: #fff;
    margin-bottom: 20px;
    @media (max-width: 720px) {
      margin: 5px 10px 20px;
      font-size: 12px;
    }
  }
  > button {
    background-color: #2176ff;
    font-family: Montserrat;
    color: #fff;
    padding: 5px;
    border-radius: 10px;
    border: none;
    margin-left: 26rem;
    margin-bottom: 5px;
    cursor: pointer;
    transition: 200ms;
    @media (max-width: 720px) {
      margin-left: 10rem;
    }
    :hover {
      transform: scale(1.05);
      transition: 200ms;
    }
  }
`;

const BarraPesquisa = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  @media (max-width: 720px) {
    padding: 0 20px 0;
  }
  > div {
    width: 560px;
    border-radius: 25px;
    font-size: 16px;
    cursor: pointer;
    > div {
      border-radius: 15px 0 0 15px;
      min-height: 44px;
      padding-left: 10px;
      text-align: start;
      @media (max-width: 720px) {
        font-size: 12px;
      }
    }
    #react-select-3-listbox {
      border-radius: 0px;
      margin-top: 4px;
      padding: 2px;
      width: 620px;
      @media (max-width: 720px) {
        width: calc(100% + 50px);
      }
    }
  }
  > button {
    color: #fff;
    font-size: 16px;
    padding: 10px;
    background-color: #2176ff;
    border-radius: 0 15px 15px 0;
    border: none;
    min-width: 60px;
    cursor: pointer;
    svg {
      transform: scale(1.4);
      margin-bottom: -4px;
    }
  }
`;
const FiltrosExtras = styled.div`
  display: flex;
  justify-content: end;
  padding: 10px;
  @media (max-width: 1000px) {
    justify-content: center;
    flex-wrap: wrap;
    padding-right: 0;
  }
  align-items: center;
  width: 100%;
  min-height: 70px;
  background-color: #fafafa;
  border-bottom: 1px solid #c5c6c7;
  padding-right: 15px;
  height: auto;
  > div {
    margin-right: 10px;
  }
  > span {
    font-size: 18px;
    margin-right: 10px;
    margin-top: 5px;
    @media (max-width: 720px) {
      width: 100%;
    }
  }
`;
const ResultadosPesquisa = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  background-color: #fff;
  padding: 20px;
`;
const NoticiaResultado = styled.div`
  width: 310px;
  height: 550px;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 1px 3px 9px -1px rgba(0, 0, 0, 0.25);
  margin: 15px;
  cursor: pointer;
  transition: 200ms;
  backface-visibility: hidden;

  img {
    height: 200px;
  }
  h2 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #2176ff;
    font-weight: 600;
    margin-bottom: 15px;
    margin-top: 15px;
    height: 95px;
    @media (max-width: 720px) {
      height: 45px;
    }
    @media (max-width: 550px) {
      height: 70px;
    }
  }
  span {
    display: flex;
    width: 100%;
    text-align: start;
    margin: 5px;
    font-size: 12px;
    color: #747a87;
  }
  p {
    height: 170px;
    margin-top: 10px;
    text-align: justify;
    font-size: 16px;
    color: #747a87;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 9;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 720px) {
      -webkit-line-clamp: 8;
      height: 152px;
    }
  }
  :hover {
    filter: brightness(0.97);
    transform: scale(1.05);
  }
`;
const PassarPaginas = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 100px;
  color: #2176ff;
  margin-bottom: 100px;
  @media (max-width: 720px) {
    justify-content: center;
    padding-right: 0;
  }

  button {
    color: #2176ff;
    border: none;
    background: none;
    font-size: 15px;
    font-weight: 600;
    font-family: Montserrat;
    cursor: pointer;
    transition: 200ms;
    :hover {
      transform: scale(1.02);
      transition: 200ms;
    }
  }
  svg {
    height: 20px;
    width: 20px;
    margin: 0 5px -6px;
  }
`;
const ContadorPagina = styled.span<{ is_selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props) => (props.is_selected ? "#2176ff" : "#000")};
  height: 100%;
  width: 40px;
  font-size: 16px;
  margin: 0 5px 0;
  color: ${(props) => (props.is_selected ? "#fff" : "#2176ff")};
  cursor: pointer;
  transition: 200ms;
  background-color: ${(props) => (props.is_selected ? "#2176ff" : "#fff")};
  :hover {
    border: 1px solid #2176ff;
    transition: 200ms;
  }
`;

export {
  LocalBusca,
  BarraPesquisa,
  ResultadosPesquisa,
  FiltrosExtras,
  NoticiaResultado,
  PassarPaginas,
  ContadorPagina,
};
