import CryptoJS from "crypto-js";
import { Noticia } from "../models/Noticia";

//TOKEN JWT
const token =
  "eyJhbGciOiJIUzI1NiJ9.bm90aWNpYQ.sbsGodORMzEAAaVwS_ctAh8cnMZVC5I4M-rDhm1ZffA";

//CHAVE DE CRIPTOGRAFIA
const chave_criptografia = "40b8c76c-618e-47ec-9473-1d59f7bb6c7b";

const getApiCriptografada = () => {
  return CryptoJS.AES.encrypt(token, chave_criptografia).toString();
};

//const localhost = "localhost:3001"; //"192.168.15.8";
const host = "api.brainmarket.com.br";
//Buscar dados do checkbox na API
async function getNoticiaTemplate(
  ordenacao_id: string,
  direcao_id: string,
  ano_maximo: number,
  ano_minimo: number,
  multiplicador: number
) {
  const response = await fetch(
    "https://" +
      host +
      "/listaNoticia/?ordenacao=" +
      ordenacao_id +
      "&direcao=" +
      direcao_id +
      "&anoMaximo=" +
      ano_maximo +
      "&anoMinimo=" +
      ano_minimo +
      "&multiplicador=" +
      multiplicador,
    {
      method: "GET",
      headers: {
        Authorization: token,
      },
    }
  );
  return (await response.json()) as Noticia[];
}
async function getNoticiaBuscada(
  ordenacao_id: string,
  direcao_id: string,
  ano_maximo: number,
  ano_minimo: number,
  palavras_chave: string[],
  multiplicador: number
) {
  let url =
    "https://" +
    host +
    "/buscarNoticia/?ordenacao=" +
    ordenacao_id +
    "&direcao=" +
    direcao_id +
    "&anoMaximo=" +
    ano_maximo +
    "&anoMinimo=" +
    ano_minimo +
    "&multiplicador=" +
    multiplicador;
  for (const palavra of palavras_chave) url += "&palavrasChave[]=" + palavra;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });
  return (await response.json()) as Noticia[];
}

export { getNoticiaTemplate, getNoticiaBuscada };
