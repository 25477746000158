import React, { useEffect, useState } from "react";

import {
  BarraPesquisa,
  ContadorPagina,
  FiltrosExtras,
  LocalBusca,
  NoticiaResultado,
  PassarPaginas,
  ResultadosPesquisa,
} from "./NoticiaBuscaStyle";

import {
  BsSearch,
  BsFillArrowLeftSquareFill,
  BsFillArrowRightSquareFill,
} from "react-icons/bs";

import Select from "react-select";
import { Noticia } from "../models/Noticia";
import {
  getNoticiaBuscada,
  getNoticiaTemplate,
} from "../FetchApi/FetchNoticia";

const noticias_por_pagina = 15;

const OrdemEnum = { DATAPOST: "1", NOMEPOSTAGEM: "2" };
const DirecaoEnum = { CRESCENTE: "1", DECRESCENTE: "2" };

const meses = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

interface SelectOption {
  label: string;
  value: string;
}

function NoticiaBusca() {
  const [contador_pagina, setContadorPagina] = useState(1);
  const [search_control, setSearchControl] = useState(0);
  const [ordem_pagina, setOrdemPagina] = useState(OrdemEnum.DATAPOST);
  const [direcao_pagina, setDirecaoPagina] = useState(DirecaoEnum.DECRESCENTE);
  const [faixa_ano, setFaixaAno] = useState({
    ano_minimo: 2021,
    ano_maximo: new Date().getFullYear(),
  });
  const [string_busca, setStringBusca] = useState("");
  const [palavras_chave, setPalavrasChave] = useState<SelectOption[]>([]);
  const [palavra_trecho, setPalavraTrecho] = useState("");
  const [resultados_busca, setResultadosBusca] = useState<Noticia[]>([]);

  const pegarUltimosAnos = () => {
    const ano_atual = new Date().getFullYear();
    const ultimos_anos: SelectOption[] = [];
    let i = 0;
    while (ano_atual - i >= 2021) {
      ultimos_anos.push({
        label: String(ano_atual - i),
        value: String(ano_atual - i),
      });
      i++;
    }
    return ultimos_anos;
  };

  const capturarTrechoPalavra = (texto: string, palavra_procurada: string) => {
    const trecho = (
      <p>
        ...
        {texto
          .substring(
            texto.toLowerCase().indexOf(palavra_procurada) - 50,
            texto.toLowerCase().indexOf(palavra_procurada)
          )
          .slice()}
        <b>
          {texto.substring(
            texto.toLowerCase().indexOf(palavra_procurada),
            texto.toLowerCase().indexOf(palavra_procurada) +
              palavra_procurada.length
          )}
        </b>
        {texto.substring(
          texto.toLowerCase().indexOf(palavra_procurada) +
            palavra_procurada.length,
          texto.toLowerCase().indexOf(palavra_procurada) +
            300 -
            palavra_procurada.length
        )}
      </p>
    );
    return trecho;
  };

  const formatarCorpoNoticia = (corpo_noticia: string) => {
    const parser = new DOMParser();
    const texto_formatado = parser
      .parseFromString(corpo_noticia, "text/html")
      .body.textContent?.replace(/\[.*?\]/g, "")
      .replace(/(https?:\/\/[^\s]+)/g, "") as string;
    if (palavra_trecho !== "") {
      const trecho_elemento = capturarTrechoPalavra(
        texto_formatado,
        palavra_trecho.toLowerCase()
      );
      return trecho_elemento;
    } else if (search_control === 0)
      return (
        <p>
          {texto_formatado?.slice(2, texto_formatado.lastIndexOf(" ", 500))}
        </p>
      );
  };

  const buscarNoticia = (multiplicador = 1) => {
    if (multiplicador <= 1) setResultadosBusca([]);
    getNoticiaBuscada(
      ordem_pagina,
      direcao_pagina,
      faixa_ano.ano_maximo,
      faixa_ano.ano_minimo,
      palavras_chave.map((palavra) => palavra.value).concat(string_busca),
      multiplicador
    ).then((result) => {
      if (result.length === 0) setSearchControl(-1);
      else setSearchControl(1);
      setResultadosBusca(result);
      if (palavras_chave.length > 0) setPalavraTrecho(palavras_chave[0].value);
      else if (string_busca !== "") setPalavraTrecho(string_busca);
    });
  };

  const pegarListaBase = (multiplicador = 1) => {
    if (multiplicador <= 1) setResultadosBusca([]);
    setSearchControl(0);
    setPalavraTrecho("");
    getNoticiaTemplate(
      ordem_pagina,
      direcao_pagina,
      faixa_ano.ano_maximo,
      faixa_ano.ano_minimo,
      multiplicador
    ).then((result) => {
      setResultadosBusca(result as Noticia[]);
    });
  };

  useEffect(() => {
    pegarListaBase();
  }, []);

  useEffect(() => {
    setContadorPagina(1);
    if (string_busca !== "" || palavras_chave.length > 0) buscarNoticia();
    else pegarListaBase();
  }, [ordem_pagina, direcao_pagina, faixa_ano]);

  useEffect(() => {
    if (
      string_busca !== "" &&
      search_control < 0 &&
      resultados_busca.length === 0
    ) {
      pegarListaBase();
      setStringBusca("");
    }
    console.log(string_busca);
  }, [string_busca]);

  useEffect(() => {
    if (noticias_por_pagina * contador_pagina >= resultados_busca.length) {
      if (string_busca !== "" || palavras_chave.length > 0)
        buscarNoticia(resultados_busca.length / (noticias_por_pagina * 3) + 1);
      else
        pegarListaBase(resultados_busca.length / (noticias_por_pagina * 3) + 1);
    }
  }, [contador_pagina]);

  return (
    <>
      <LocalBusca>
        <h1>Buscar Notícia</h1>
        <h4>
          Adicione palavras-chave relevantes, e em seguida clique no botão de
          Pesquisa
        </h4>

        <button
          style={{ visibility: string_busca === "" ? "hidden" : "visible" }}
          onClick={() => {
            setPalavrasChave(
              palavras_chave.concat({
                label: string_busca,
                value: string_busca,
              })
            );
            setStringBusca("");
          }}
        >
          Adicionar Palavra chave
        </button>
        <BarraPesquisa>
          <Select
            isMulti
            isClearable
            inputValue={string_busca}
            options={[
              { value: "Óleo e Gás", label: "Óleo e Gás" },
              { value: "Energia", label: "Energia" },
              { value: "Agroindústria", label: "Agroindústria" },
              { value: "Petroquímica", label: "Petroquímica" },
              { value: "Cubatão", label: "Cubatão" },
              { value: "Salvador", label: "Salvador" },
            ]}
            noOptionsMessage={() =>
              "Adicione a palavra chave, pressione Enter, ou clique no botão de busca"
            }
            placeholder="Busque a Notícia"
            onInputChange={(query) => {
              if (
                !(string_busca.length > 1 && query === "") &&
                query.match(/^[a-zA-ZÀ-ÖØ-öø-ÿ0-9\s]*$/)
              )
                setStringBusca(query);
            }}
            onChange={(e) => {
              setPalavrasChave(e as SelectOption[]);
            }}
            value={palavras_chave}
            onKeyDown={(e) => {
              if (
                e.key === "Enter" &&
                (string_busca.length > 1 || palavras_chave.length > 0)
              )
                buscarNoticia();
            }}
          />
          <button
            onClick={() => {
              if (string_busca.length > 1 || palavras_chave.length > 0)
                buscarNoticia();
            }}
          >
            <BsSearch />
          </button>
        </BarraPesquisa>
      </LocalBusca>
      <FiltrosExtras>
        <span>Filtros:</span>
        <div>
          <span>Ano mínimo</span>
          <Select
            isClearable={false}
            isSearchable={false}
            defaultValue={pegarUltimosAnos()[2]}
            options={pegarUltimosAnos()}
            onChange={(e) => {
              if (parseInt(e?.value ?? "0") !== faixa_ano.ano_minimo)
                setFaixaAno({
                  ano_maximo: faixa_ano.ano_maximo,
                  ano_minimo: parseInt(e?.value ?? "0"),
                });
            }}
          />
        </div>
        <div>
          <span>Ano máximo</span>
          <Select
            isClearable={false}
            isSearchable={false}
            placeholder="Selecione um filtro"
            defaultValue={pegarUltimosAnos()[0]}
            options={pegarUltimosAnos()}
            onChange={(e) => {
              if (parseInt(e?.value ?? "0") !== faixa_ano.ano_maximo)
                setFaixaAno({
                  ano_minimo: faixa_ano.ano_minimo,
                  ano_maximo: parseInt(e?.value ?? "0"),
                });
            }}
          />
        </div>
        <div>
          <span>Ordernar por: </span>
          <Select
            isClearable={false}
            isSearchable={false}
            defaultValue={{
              value: OrdemEnum.DATAPOST,
              label: "Data de Postagem",
            }}
            options={[
              { value: OrdemEnum.DATAPOST, label: "Data de Postagem" },
              { value: OrdemEnum.NOMEPOSTAGEM, label: "Ordem Alfabética" },
            ]}
            onChange={(e) => {
              if (e?.value !== ordem_pagina) setOrdemPagina(e?.value ?? "");
            }}
          />
        </div>
        <div>
          <span>Ordem: </span>
          <Select
            isClearable={false}
            isSearchable={false}
            defaultValue={{
              value: DirecaoEnum.DECRESCENTE,
              label: "Decrescente",
            }}
            options={[
              { value: DirecaoEnum.CRESCENTE, label: "Crescente" },
              { value: DirecaoEnum.DECRESCENTE, label: "Decrescente" },
            ]}
            onChange={(e) => {
              if (e?.value !== direcao_pagina) setDirecaoPagina(e?.value ?? "");
            }}
          />
        </div>
      </FiltrosExtras>
      <ResultadosPesquisa>
        {resultados_busca.length !== 0 ? (
          resultados_busca
            ?.slice(
              (contador_pagina - 1) * noticias_por_pagina,
              noticias_por_pagina * contador_pagina >= resultados_busca?.length
                ? resultados_busca?.length
                : noticias_por_pagina * contador_pagina
            )
            .map((resultado) => {
              return (
                <NoticiaResultado
                  key={resultado.id}
                  onClick={() => (window.location.href = resultado.guid)}
                >
                  <img src={resultado.image_url} alt="imagem noticia" />
                  <h2>{resultado.post_title}</h2>
                  <span>
                    {resultado.post_date.substring(8, 10) +
                      " de " +
                      meses[new Date(resultado.post_date).getMonth()] +
                      " de " +
                      new Date(resultado.post_date).getFullYear()}
                  </span>
                  {formatarCorpoNoticia(resultado.post_content)}
                </NoticiaResultado>
              );
            })
        ) : search_control < 0 ? (
          <h1>Sem Resultados :(</h1>
        ) : (
          <h1>Carregando...</h1>
        )}
      </ResultadosPesquisa>

      {resultados_busca.length > 0 ? (
        <PassarPaginas>
          {contador_pagina > 1 ? (
            <button
              onClick={() => {
                setContadorPagina(contador_pagina - 1);
                window.scrollTo({
                  top: 400,
                  behavior: "smooth", // Scroll suave, se suportado pelo navegador
                });
              }}
            >
              <BsFillArrowLeftSquareFill /> Anterior
            </button>
          ) : null}

          {contador_pagina > 1 ? (
            <ContadorPagina
              is_selected={false}
              onClick={() => {
                setContadorPagina(contador_pagina - 1);
                window.scrollTo({
                  top: 400,
                  behavior: "smooth", // Scroll suave, se suportado pelo navegador
                });
              }}
            >
              {contador_pagina - 1}
            </ContadorPagina>
          ) : null}
          <ContadorPagina is_selected={true}>{contador_pagina}</ContadorPagina>
          {contador_pagina * noticias_por_pagina < resultados_busca.length ? (
            <ContadorPagina
              is_selected={false}
              onClick={() => {
                setContadorPagina(contador_pagina + 1);
                window.scrollTo({
                  top: 400,
                  behavior: "smooth", // Scroll suave, se suportado pelo navegador
                });
              }}
            >
              {contador_pagina + 1}
            </ContadorPagina>
          ) : null}
          {contador_pagina * noticias_por_pagina < resultados_busca.length ? (
            <button
              onClick={() => {
                setContadorPagina(contador_pagina + 1);
                window.scrollTo({
                  top: 400,
                  behavior: "smooth", // Scroll suave, se suportado pelo navegador
                });
              }}
            >
              Próximo
              <BsFillArrowRightSquareFill />
            </button>
          ) : null}
        </PassarPaginas>
      ) : null}
    </>
  );
}

export default NoticiaBusca;
