import React, { useState } from "react";
import { GavetaMobile, Nav } from "./NavBarStyle";
import { FaBars } from "react-icons/fa";
import LogoBrain from "../assets/Brainmarket-logo.png";

function NavBar() {
  const [gaveta_mobile_abrir, setGavetaMobileAbrir] = useState(false);
  return (
    <>
      <Nav>
        <div>
          <a href="https://www.brainmarket.com.br/">
            <img src={LogoBrain} alt="Logo Brainmarket" />
          </a>
          <div>
            <table>
              <thead>
                <tr>
                  <th>
                    <a href="https://www.brainmarket.com.br/">HOME</a>
                  </th>
                  <th>
                    <a href="https://www.brainmarket.com.br/sobre/">
                      SOBRE NÓS
                    </a>
                  </th>
                  <th>
                    <a href="https://www.brainmarket.com.br/parceiros/">
                      PARCEIROS
                    </a>
                  </th>
                  <th>
                    <a href="https://www.brainmarket.com.br/noticias/">
                      NOTÍCIAS
                    </a>
                  </th>
                  <th>
                    <a href="https://blog.brainmarket.com.br/">BLOG</a>
                  </th>
                  <th>
                    <a href="https://www.brainmarket.com.br/intranet/">
                      INTRANET
                    </a>
                  </th>
                  <th>
                    <a href="https://www.brainmarket.com.br/contato/">
                      <span>CONTATO</span>
                    </a>
                  </th>
                </tr>
              </thead>
            </table>
            <button onClick={() => setGavetaMobileAbrir(!gaveta_mobile_abrir)}>
              <FaBars />
            </button>
          </div>
        </div>
      </Nav>
      <GavetaMobile is_open={gaveta_mobile_abrir}>
        <table>
          <tbody>
            <tr>
              <td>
                <a href="https://www.brainmarket.com.br/">HOME</a>
              </td>
            </tr>
            <tr>
              <td>
                <a href="https://www.brainmarket.com.br/sobre/">SOBRE NÓS</a>
              </td>
            </tr>
            <tr>
              <td>
                <a href="https://www.brainmarket.com.br/parceiros/">
                  PARCEIROS
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a href="https://www.brainmarket.com.br/noticias/">NOTÍCIAS</a>
              </td>
            </tr>
            <tr>
              <td>
                <a href="https://blog.brainmarket.com.br/">BLOG</a>
              </td>
            </tr>
            <tr>
              <td>
                <a href="https://www.brainmarket.com.br/intranet/">INTRANET</a>
              </td>
            </tr>
            <tr>
              <td>
                <a href="https://www.brainmarket.com.br/contato/">
                  <span>CONTATO</span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </GavetaMobile>
    </>
  );
}

export default NavBar;
