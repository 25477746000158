import styled from "styled-components";

const nav_height = "100px";

const Nav = styled.div`
  //background: linear-gradient(to top, #011229 0%, #032c4b 100%);
  background-color: #0d1b30;
  height: ${nav_height};
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  position: absolute;
  margin-bottom: ${nav_height};
  z-index: 1;
  > div {
    text-decoration: none;
    color: #fff;
    font-size: 3rem;
    margin: 0 20px 0 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    img {
      height: 80px;
      @media (max-width: 720px) {
        height: 60px;
      }
    }
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      table {
        @media (max-width: 1000px) {
          display: none;
        }
      }
      th a {
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 1px;
        text-decoration: none;
        color: #fff;
        margin-right: 23px;
        vertical-align: middle;
        transition: 250ms;
        > span {
          font-family: Roboto;
          padding: 13px 29px;
          background-color: #2176ff;
          border: none;
          border-radius: 25px;
          letter-spacing: 2px;
          font-weight: 600;
          transition: 250ms;
        }
        > span:hover {
          background-color: #000d68;
          color: #fff;
          transition: 250ms;
        }
      }
      th a:hover {
        color: #02a0fc;
        transition: 250ms;
      }
      > button {
        display: none;
        @media (max-width: 1000px) {
          display: flex;
        }
        background: none;
        border: none;
        color: #fff;
        > svg {
          height: 30px;
          width: 30px;
          margin-right: 10px;
        }
      }
    }
  }
`;
const GavetaMobile = styled.div<{ is_open: boolean }>`
  visibility: ${(props) => (props.is_open ? "visible" : "hidden")};
  height: ${(props) => (props.is_open ? "auto" : "0")};
  width: 100%;
  background-color: #777;
  padding-top: ${nav_height};
  table {
    width: 100%;
    td a {
      display: flex;
      width: 100%;
      height: 50px;
      justify-content: start;
      align-items: center;
      padding: 10px;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 1px;
      text-decoration: none;
      color: #fff;
      vertical-align: middle;
      background-color: #323643;
      transition: 100ms;
    }
  }
`;
export { Nav, GavetaMobile };
